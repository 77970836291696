@import "@/styles/_variables";
.nav {
  width: 100%;
  font-family: "Poppins", sans-serif;
  background: $main-color;
  .topbar {
    display: flex;
    justify-content: space-between;
    a {
      &:hover {
        color: #fff;
      }
    }
    .navlist1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
      margin: 0px;

      li {
        list-style: none;
        span {
          color: $primary-color-light;
          font-size: 13px;
        }
      }

      width: 40%;
      .navitem1 {
        flex-shrink: 0;
        flex-grow: 0;
        font-weight: normal;
        padding: 0px 10px;
        position: relative;
        cursor: pointer;
        font-size: 15px;
        line-height: 40px;
        color: $primary-color-light;

        a {
          color: $primary-color-light;
          display: flex;
          align-items: center;
          font-size: 15px;
          span {
            padding-left: 5px;
            font-size: 15px;
          }
        }
      }
    }
    .nav_list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 60%;
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        span {
          color: $primary-color-light;
          font-size: 15px;
        }
      }

      .nav_item {
        flex-shrink: 0;
        flex-grow: 0;
        font-weight: normal;
        padding: 0px 10px;
        position: relative;
        cursor: pointer;
        line-height: 20px;
        font-size: 14px;
        color: $primary-color-light;
        a {
          i {
            font-size: 20px;
          }
        }
        span {
          padding: 2px 6px;
          border: 1px solid #fff;
          font-size: 13px;
        }

        &:hover {
          ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.cart_item {
  position: absolute;
  top: -7px;
  right: 0;
}
@media (min-width: 220px) and (max-width: 1199px) {
  .nav {
    .topbar {
      display: none;
    }
  }
}
