.footer_desktop {
  position: relative;

  .footer_top_a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .footer_top {
    width: 100%;
    padding: 0;
    position: relative;

    .footer_top_image {
      max-height: 556px;
      position: relative;
    }

    span {
      position: relative;
      width: 100% !important;
      img {
        width: 100% !important;
        object-fit: cover;
      }
    }
    .app_logo {
      max-width: 250px !important;
      width: 100% !important;
      img {
        width: 100% !important;
      }
    }
  }
  .middle_section {
    .h3 {
      font-size: 24px;
      margin: 0 0 20px 0;
      color: #000;
      line-height: 1;
      font-weight: 500;
      padding-top: 60px;
    }
    .h4 {
      font-weight: 600;
      font-size: 35px;
      color: #000;
      position: relative;
      padding-bottom: 12px;
      line-height: 1.2;
      padding-top: 70px;
      span {
        color: #fd1b2f;
      }
    }
  }
  .border_top_1 {
    border-top: 1px solid #ccc;
  }
  .footer_bottom_link {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 40px 0px;
  }
  .footer_bottom {
    background-color: #ff1933;
    padding: 40px 0 15px 0;

    img {
      width: 100% !important;
      max-width: 231px !important;
      object-fit: cover;
    }
  }
  .footer_links {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 6px 0;
        display: flex;
        align-items: center;
        a {
          color: #fff;

          transition: 0.3s;
          display: inline-block;
          line-height: 1.4;
          font-weight: 500;
          font-size: 15px;
          font-family: "Open Sans", sans-serif;
        }
      }
    }
  }
  .copyright {
    padding-top: 30px;
    color: #fff;
    font-weight: 300;
    strong {
      font-weight: 300;
    }
    p {
      color: #fff;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .credits {
    padding-top: 24px;
    display: flex;
    font-size: 15px;
    color: #fff;
    text-align: center;
    font-weight: 300;
    li {
      list-style: none;
      padding-left: 20px;
      position: relative;
      font-family: "Open Sans", sans-serif;
      &:after {
        content: "|";
        position: absolute;
        top: 0px;
        left: 8px;
        color: #fff;
      }
      &:first-child {
        &:after {
          display: none;
        }
      }
      a {
        color: #fff;
        transition: 0.3s;
        font-weight: 500;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
      }
    }
  }
}
.footer_mobile {
  display: none;
}
.social_links1 {
  a {
    font-size: 28px;
    padding: 0 10px;
    svg {
      width: 25px;
      height: 25px;
    }
    &:hover {
      color: #fff;
    }
  }
}
.certificationbadge {
  display: flex;
  justify-content: flex-end;
  img {
    max-height: 76px;
  }
}
@media (min-width: 1900px) and (max-width: 20000px) {
  .footer_top_image {
    max-height: 820px !important;
    position: relative;
    overflow: hidden;
  }
}
@media (min-width: 1600px) and (max-width: 1899px) {
  .footer_top_image {
    max-height: 599px !important;
    position: relative;
    overflow: hidden;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .footer_top_image {
    max-height: 597px !important;
    position: relative;
    overflow: hidden;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer_desktop {
    .copyright {
      p {
        text-align: center;
        margin-bottom: 0px;
        font-size: 13.5px;
      }
    }
    .credits {
      padding-top: 19px;
      display: flex;
      font-size: 14px;
      color: #fff;
      text-align: center;
      justify-content: center;
      li {
        list-style: none;
        padding-left: 10px;
        position: relative;
        text-align: center;
        &:after {
          content: "|";
          position: absolute;
          top: 0px;
          left: 3px;
          color: #fff;
        }
        &:first-child {
          &:after {
            display: none;
          }
        }
        a {
          color: #fff;
          transition: 0.3s;
          font-weight: 600;
        }
      }
    }
    .middle_section {
      .h3 {
        font-size: 20px;
        margin: 0 0 25px 0;
        color: #000;
        line-height: 1;
        font-weight: 500;
        padding-top: 30px;
      }
      .h4 {
        font-weight: 600;
        font-size: 30px;
        color: #000;
        position: relative;
        padding-bottom: 12px;
        margin-bottom: 0px;
        span {
          color: #fd1b2f;
        }
      }
      .app_logo {
        max-width: 200px !important;
        width: 100% !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .credits {
    max-height: 180px;
  }
  .social_links1 a {
    font-size: 16px;
    padding: 0 4px;
  }

  .footer_mobile {
    width: 100% !important;
    .footer_top {
      width: 100% !important;
      span {
        width: 100% !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
  .footer_desktop {
    .copyright {
      padding: 30px 0px;
      border-bottom: 0px solid #fff;
      p {
        text-align: center;
        margin-bottom: 0px;
        font-size: 11px;
      }
    }
    .credits {
      justify-content: center;
    }
    .middle_section {
      .h3 {
        font-size: 20px;
        margin: 0 0 10px 0;
        color: #000;
        line-height: 1;
        font-weight: 500;
        padding-top: 20px;
      }
      .h4 {
        font-weight: 600;
        font-size: 25px;
        color: #000;
        position: relative;
        padding-bottom: 12px;
        margin-bottom: 0px;
        span {
          color: #fd1b2f;
        }
      }
      .app_logo {
        max-width: 140px !important;
        width: 100% !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
  .iso {
    max-width: 200px;
    img {
      -o-object-fit: fill !important;
      object-fit: fill !important;
    }
  }
  .copyright p {
    font-size: 11px;
  }
}
@media (min-width: 220px) and (max-width: 767px) {
  .social_links1 {
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
  }
  .footer_desktop {
    display: none;
  }
  .footer_mobile {
    display: block;
    .footer_top_a {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    .footer_top {
      width: 100%;
      padding: 0;
      position: relative;
      background-color: #f5f5f5;
    }
    .middle_section {
      .app_logo,
      .gpay {
        max-width: 140px;
        display: block;
      }
      .h3 {
        font-size: 24px;
        margin: 0 0 20px 0;
        color: #000;
        line-height: 1;
        font-weight: 500;
        padding-top: 60px;
      }
      .h4 {
        font-weight: 400;
        font-size: 28px;
        color: #000;
        position: relative;
        padding-bottom: 0px;
        padding-top: 30px;
        line-height: 1.2;
        font-family: "newyork";
        span {
          color: #fd1b2f;
        }
      }
    }
    .border_top_1 {
      border-top: 1px solid #ccc;
    }
    .footer_bottom_link {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 40px 0px;
    }
    .footer_bottom {
      background-color: #ff1933;
      padding: 40px 0;
    }
    .footer_links {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          padding: 7px 0;
          display: flex;
          align-items: center;
          a {
            color: #fff;

            transition: 0.3s;
            display: inline-block;
            line-height: 1.4;
            font-size: 15px;
            font-family: "Open Sans", sans-serif;
            font-weight: 500;
          }
        }
      }
    }
    .copyright {
      padding: 20px 0;
      margin-bottom: 0px;
      color: #fff;
      border-top: 1px solid #fff;
      p {
        margin-bottom: 0px;
        font-size: 15px;
        font-family: "Open Sans", sans-serif;
        text-align: center;
        font-weight: 500;
      }
    }
    .credits {
      padding: 5px 0;
      display: block;
      font-size: 15px;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #fff;
      text-align: center;
      clear: both;
      li {
        list-style: none;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        position: relative;
        text-align: center;
        display: inline-block;
        &:after {
          content: "|";
          position: absolute;
          top: 3px;
          left: 3px;
          color: #fff;
        }
        &:first-child {
          padding-left: 0px;
          &:after {
            display: none;
          }
        }
        &:last-child {
          padding-left: 0px;
          &:after {
            display: none;
          }
        }
        a {
          color: #fff;
          transition: 0.3s;
          font-weight: 500;
          font-size: 15px;
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
        }
      }
    }
  }
}
// iner page footer
.inerpage_footer {
  width: 100%;
  background-color: #fff;
  padding-top: 10px;
  p {
    font-size: 15px;
    color: #000;
  }
  .copyright {
    padding-top: 20px;
    color: #000;
    padding-bottom: 20px;
  }
  .credits {
    padding-top: 20px;
    display: flex;
    font-size: 15px;
    padding-bottom: 20px;
    color: #000;
    text-align: center;
    li {
      list-style: none;
      padding-left: 20px;
      position: relative;
      &:after {
        content: "|";
        position: absolute;
        top: 0px;
        left: 8px;
        color: #000;
      }
      &:first-child {
        &:after {
          display: none;
        }
      }
      a {
        color: #000;
        transition: 0.3s;
        font-weight: 600;
      }
    }
  }
}
.go_to_top {
  position: fixed;
  bottom: 140px;
  right: 35px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f3e2c7; /* Old browsers */
  background: -moz-linear-gradient(
    to bottom,
    #d9a85c 0%,
    #fedd78 50%,
    #fcdb77 51%,
    #dfb471 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    to bottom,
    #d9a85c 0%,
    #fedd78 50%,
    #fcdb77 51%,
    #dfb471 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #d9a85c 0%,
    #fedd78 50%,
    #fcdb77 51%,
    #dfb471 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3e2c7', endColorstr='#e9d4b3',GradientType=0 );

  box-shadow: 0 2px 6px 0 rgb(32 0 0 / 40%);
  text-align: center;
  z-index: 99999;
  cursor: pointer;
  i {
    font-size: 20px;
    line-height: 58px;
    color: #000;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .go_to_top {
    position: fixed;
    bottom: 28px !important;
    right: 18px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    // background: linear-gradient(90deg, #ff1934 0%, #ea00b8 100%);
    background-color: #222;
    box-shadow: 0 18px 30px -12px rgb(0 0 0 / 20%);
    text-align: center;
    z-index: 99999;
    cursor: pointer;
    i {
      font-size: 20px;
      line-height: 58px;
      color: #fff;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer_mobile {
    width: 100% !important;
    .footer_top {
      width: 100% !important;
      span {
        width: 100% !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
  .inerpage_footer {
    .copyright {
      text-align: center;
      padding: 15px 0px;
      padding-top: 0px;
      margin-bottom: 0px;
    }
    .credits {
      padding-top: 0px;
      display: block;
      font-size: 15px;
      padding-bottom: 20px;
      color: #000;
      text-align: center;
      li {
        list-style: none;
        padding-left: 20px;
        position: relative;
        &:after {
          display: none;
        }
        &:first-child {
          &:after {
            display: none;
          }
        }
        a {
          color: #000;
          transition: 0.3s;
          font-weight: 600;
          padding: 5px 0px;
          display: block;
        }
      }
    }
  }
}

.social_links {
  text-align: center;
  justify-content: center;
  a {
    font-size: 35px;
    display: inline-block;
    color: #ff1d25 !important;
    line-height: 1;
    padding: 8px 15px;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    transition: 0.3s;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}
.download p {
  color: #000;
  text-transform: uppercase;
  padding: 15px 0;
  margin-bottom: 0;
}
