@import "@/styles/_variables";

.header_logo_section {
  display: flex;
  justify-content: space-between;
  .w_60 {
    width: 59%;
    display: flex;
    justify-content: space-between;
  }

  .header_icon_box {
    display: flex;
    padding-top: 15px;

    .header_icon {
      i {
        font-size: 15px;
        color: rgb(153, 153, 153);
      }
      img {
        width: 100%;
        padding-left: 23px;
        padding-top: 7px;
      }
    }
    .header_text {
      margin-left: 10px;
      font-size: 14px;
      color: $main-color;
      text-transform: uppercase;
    }
  }
  .logo {
    text-align: center;
    justify-content: center;
    display: flex;
    align-self: center;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    width: 41%;
    align-self: center;
    position: relative;
  }
}

.search_form {
  width: 300px;
  height: 40px;
  position: relative;
  border-bottom: 1px solid #333 !important;

  input {
    width: 100%;
    height: 100%;
    padding: 0 50px 0 0px;
    border-bottom: 0px solid #333 !important;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 16px;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #000;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
    opacity: 1;
  }

  ::placeholder {
    color: #000;
    opacity: 1;
  }
}

.search_button {
  width: 40px;
  height: 35px;
  display: block;
  position: absolute;
  top: 2px;
  right: 0;
  overflow: hidden;
  border: 0px;
  background-color: $primary-color-light;
  text-decoration: none;

  &.typed .icon {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border-width: 6px;
    top: -11px;
    left: -11px;
    &:after {
      width: 52px;
      height: 8px;
      bottom: -20px;
      right: -30px;
    }
    .clear {
      line-height: 58px;
      font-size: 22px;
    }
  }
}
@media (min-width: 220px) and (max-width: 1199px) {
  .main_header {
    display: none;
  }
  .header_logo_section {
    display: none;
  }
}

.main_header {
  background-color: #ffffff;
  padding: 10px 0;
  padding-top: 5px;
  .top-text-topbar {
    padding-bottom: 5px;
  }
  .header_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 170px;
      margin-top: 1px;
      img {
        // border: 1px solid #ff1933 !important;
        // border-radius: 5px !important;
      }
    }
    .users_container {
      width: 25%;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
        li {
          padding: 5px;
          position: relative;
          text-align: center;
          span {
            display: block;
            font-size: 10.5px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
          a {
            color: #000;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            cursor: pointer;
            position: relative;
            span {
              display: block;
              font-size: 10.5px;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
            .top_card {
              position: absolute;
              right: 6px;
              top: -6px;
              background-color: #fff;
              color: #ff1933;
              font-size: 14px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              font-weight: 600;
              line-height: 19px;
              text-align: center;
            }
            .top_card_card {
              position: absolute;
              right: -3px;
              top: -6px;
              background-color: #fff;
              color: #ff1933;
              font-size: 14px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              font-weight: 600;
              text-align: center;
              line-height: 19px;
            }
          }
        }
      }
    }
    .search {
      width: 30%;
      border-radius: 5px;
      margin-right: 10px;
      margin-top: 0px;
      .search_form {
        width: 100%;
        height: 40px;
        position: relative;
        border: 1px solid #222 !important;
        border-radius: 5px;

        input {
          width: 100%;
          height: 100%;
          padding: 0 50px 0 10px;
          border-bottom: 0px solid #fff !important;
          border: none;
          outline: none;
          box-sizing: border-box;
          font-size: 16px;
          border-radius: 5px;
        }
        ::-webkit-input-placeholder {
          /* Edge */
          color: rgb(189, 185, 185);
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgb(189, 185, 185);
        }

        ::placeholder {
          color: rgb(189, 185, 185);
        }
      }
      .search_button {
        width: 40px;
        height: 29px;
        display: block;
        position: absolute;
        top: 5px;
        right: 2px;
        overflow: visible;
        border: 0px;
        background-color: $primary-color-light;
        text-decoration: none;

        &.typed .icon {
          width: 60px;
          height: 60px;
          border-radius: 50px;
          border-width: 6px;
          top: -11px;
          left: -11px;
          &:after {
            width: 52px;
            height: 8px;
            bottom: -20px;
            right: -30px;
          }
          .clear {
            line-height: 58px;
            font-size: 22px;
          }
        }
      }
    }
  }
}
.custom_flex {
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  .drop_down_main {
    a {
      color: #111;
      justify-content: flex-start;
    }
  }
  .drop_price_change {
    background-color: transparent;
    color: #111;
    display: flex;
    padding: 5px 10px;
    border: 0;
    &::after {
      margin-top: 10px;
    }
  }
  :global(.dropdown-menu) {
    border: 1px solid #f5f5f5;
    box-shadow: 0 16px 23px 0 hsla(0, 0%, 43%, 0.4);
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    li {
      padding: 5px;
      a {
        color: #fff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          color: #000;
          font-size: 12.5px;
          padding-top: 3px;
          span {
            margin-left: 7px !important;
          }
        }
        img {
        }
      }
    }
  }
}
.search {
  position: relative;
}
.suggestions {
  display: block;
  position: absolute;
  border: 0.0625rem solid #d5d5d5;
  border-radius: 0.3125rem;
  background-color: #fff;
  top: 3.75rem;
  left: 0;
  width: 550px;
  z-index: 9999;
  min-height: 20.3125rem;
  cursor: default;
  box-shadow: inset 0 0.188rem 0.375rem rgba(0, 0, 0, 0.160784);
  padding: 15px;
  max-height: 83vh;
  overflow-y: auto;
  .suggestions_section_header {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 3px 0;
        img {
          width: 20px !important;
          height: auto !important;
        }
        .span {
          width: 10px !important;
          height: auto;
        }
      }
    }
  }
  .border_contant {
    padding: 5px 0;
    li {
      display: inline-block;
      margin: 5px;
      margin-left: 0;
      margin-right: 10px;
      a {
        border: 1px solid #ddd;
        padding: 3px 10px;
        display: inline-block;
        border-radius: 20px;
      }
    }
  }
  .popular_products {
    width: 90%;
    position: relative;
    padding: 5px 0px;
    margin: 0 auto;
    .popular_products_card {
      display: block;
      width: 100%;
      margin: 0;
      min-height: 198px;
      padding: 5px;
      position: relative;
      padding-bottom: 0;
      background-color: #fff;
      transition: box-shadow 0.3s;
      border: 1px solid #ddd;
      height: 96%;
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }
}
.swiper_card_border {
  display: block;
  clear: both;
  height: 100%;
  position: relative;
  min-height: 198px;
}
.popular_products_search {
  .swiper-button-next {
    right: -5px;
    &::after {
      font-size: 20px;
    }
  }
  .swiper-button-prev {
    left: -5px;
    &::after {
      font-size: 20px;
    }
  }
}
.popular_products_search .arrow_left {
  position: absolute;
  left: -12px;
  width: 30px;

  content: "";
  top: 40%;
  background: transparent;
  border: 0;
  font-size: 30px;
}
.popular_products_search {
  position: relative;
}
.popular_products_search .arrow_right {
  position: absolute;
  right: -12px;
  width: 30px;

  content: "";
  top: 40%;
  background: transparent;
  border: 0;
  font-size: 30px;
}
.crosss {
  width: 10px !important;
  height: 10px !important;
}
.drop_hover {
  display: none;
}

.search {
  position: relative;

  .icon {
    position: relative;

    .drop_hover {
      display: block;
      width: 210px;
      position: absolute;
      left: -84px;
      z-index: 99;
      margin-top: -7px;
      i {
        font-size: 24px;
        color: #fffcfc;
        height: 20px;
        display: inline-block;
      }
      .drop_hover_head {
        width: 100%;
        margin-top: -10px;
      }
      .drop_card {
        width: 100%;
        background-color: #fffcfc;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2), 0 7px 6px rgba(0, 0, 0, 0.4);
        border-bottom: 4px solid #ff3139;
        a {
          float: right;
          color: #000;
          font-size: 18px;
          height: 18px;
          i {
            color: #222 !important;
          }
        }
        p {
          font-size: 15px;
        }
        .visual {
          font-size: 18px;
          text-align: center;
          font-weight: 600;
          color: #222;
          margin: 4px 0;
        }
        .take {
          font-size: 14px;
          text-align: center;
          color: #555;
        }
      }
    }
  }
}
