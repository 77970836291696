@import "_variables";
.main_nav {
  width: 100%;
  background: #333;
  height: 52px;
  display: flex;
  align-items: center;
  .container {
    position: inherit !important;
  }
  .nav_container {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    border-top: 0px solid #ff1933;
    padding-top: 15px;
  }
  .nav_container.large_height_second_level {
    .dropdown_column_list_2 {
      ul {
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5;
        width: 100%;
      }
    }
    &::after {
      height: 1px;
    }
  }

  .nav2 {
    background-color: $primary-color-light;
    width: 100%;
    height: 100%;
    border-top: 1px solid #ff1933;
    border-bottom: 1px solid #ff1933;
    .container {
      position: inherit !important;
    }
    .container {
      position: inherit;
    }
  }

  .main_menu {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    li {
      padding-top: 13px;
      transition: 0.3s ease-in-out;
      height: 100%;
      display: flex;
      padding-bottom: 13px;
      a {
        font-size: 15px;
        color: #3e3d3d;

        padding-bottom: 0px;
        border-bottom: 2px solid $primary-color-light;
        position: relative;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        // font-weight: 600;
        h1 {
          font-size: 15px;
          color: #3e3d3d !important;
          text-transform: uppercase;
          position: relative;
          font-weight: 700;
          font-family: "Open Sans", sans-serif;
          margin: 0px;
        }
      }

      &:hover > a {
        color: #ff1933 !important;
        border-bottom: 2px solid #ff1933;
      }
      .m-color {
        background-color: #ff1933 !important;
        padding-top: 0px !important;
      }
      .fw_dropdown {
        position: absolute;
        top: 131px;
        left: 0;
        display: none;
        width: 100%;
        text-align: left !important;
        padding: 0px 0;
        padding-left: 0px;
        padding-bottom: 0px;
        color: #000;
        justify-content: flex-start;
        z-index: 9999;
        border-bottom: 0px solid #ddd;
        box-shadow: 0 18px 20px -12px rgba(0, 0, 0, 0.2);
        // background-image: linear-gradient(
        //   to bottom,
        //   #fff,
        //   #fff,
        //   #f5f5f5,
        //   #f7f7f7,
        //   #fdd5d9,
        //   #fdd5d9
        // );
        // background: linear-gradient(
        //   345deg,
        //   rgba(254, 103, 116, 1) 0%,
        //   rgba(254, 237, 237, 1) 49%,
        //   rgba(255, 255, 255, 1) 100%
        // );
        background-color: #fff;
        ul {
          flex: 15%;
          text-align: left !important;
          padding-top: 10px !important;
          .text-grey {
            color: #838383 !important;
          }
          .explore-btn {
            margin-top: 60px;
            i {
              color: $main-color;
            }
          }
        }
        .dropdown-column-list {
          width: 100%;
          display: flex;
          justify-content: center;
          padding-top: 0px !important;
          li {
            .sub_link {
              color: #fff !important;
              font-size: 16px;
              font-weight: 400;
              text-align: left !important;
              text-transform: uppercase;
              padding: 10px 20px;
              &:hover {
                color: #000 !important;
              }
            }

            .fw_dropdown2 {
              position: absolute;
              top: 45px;
              left: 0;
              display: none;
              width: 100%;
              padding: 10px 0 20px;
              background: #fff;
              &::after {
                background-color: #ff1933;
                position: absolute;
                left: 0;
                width: 100%;
                content: "";
                height: 40px;
              }
              .container {
                position: relative !important;
              }
              ul {
                width: 20%;
                padding: 0 5px;
                float: left;
                li {
                  a {
                    padding: 7px 0px !important;
                  }
                }
              }
            }
            &:hover {
              .fw_dropdown2 {
                display: block;
              }
            }
          }
        }
        .dropdown_column_list_2 {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding-top: 0px !important;
          .dropdown_header_text {
            font-size: 15px;
            color: #252324 !important;
            display: block !important;
            padding: 7px 2px !important;
            cursor: pointer;
            position: relative;
            font-weight: 700;
            border-bottom: 1px solid #252324;
            font-family: "Open Sans", sans-serif;
            z-index: 99;
            margin: 0px;
            margin-bottom: 5px;
            line-height: 1.2;
          }
          ul {
            width: 12.5%;
            float: left;
            padding-top: 0px !important;
            padding: 0px 7px;
            padding-bottom: 20px;
          }
          li {
            text-align: center !important;
            display: block !important;
            padding: 4px 2px !important;
            line-height: 20px !important;
            .sub_link {
              color: #fff !important;
              font-size: 13px !important;
              font-weight: 400;
              text-align: left !important;

              padding: 10px 20px;
              &:hover {
                color: #000 !important;
              }
            }
            .last_link {
              color: #333 !important;
              font-size: 15px !important;
              font-weight: 500;
              text-align: left !important;

              padding: 3px 3px !important;
              display: block;
              font-family: "Open Sans", sans-serif;
              h1 {
                color: #333 !important;
                font-size: 15px !important;
                font-weight: 500;
                text-align: left !important;
                text-transform: uppercase;
                display: block;
                font-family: "Open Sans", sans-serif;
                margin: 0px;
                &:hover {
                  color: #ff1933 !important;
                  text-decoration: underline;
                }
              }
            }
            &:hover {
              .fw_dropdown2 {
                display: block;
              }
            }
          }
        }
        h5 {
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
        }
        li {
          line-height: 10px !important;
          text-align: left !important;
          height: auto;
          font-size: 15px;
          font-weight: 400;
          text-align: left !important;
          border: none !important;
          &:hover > a {
            border: none;
          }
          a {
            font-size: 14px;
            font-weight: 400;
            text-align: left !important;
            line-height: 16px;
            border: 0px;
            padding: 4px 0px;
          }
          padding: 5px 10px;
          &:hover {
            background: transparent;
            border: 0px !important;
          }
        }
        .header-img {
          img {
            height: 100%;
            text-align: flex-end;
            min-height: 315px;
          }
        }
      }

      &:hover .fw_dropdown {
        display: flex;
      }
      .fw_dropdown3 {
        position: absolute;
        top: 190px;
        left: auto;
        right: auto;
        display: none;
        width: 200px;
        padding: 10px 0 20px;
        background: #fff;
        z-index: 999;
        margin-left: -79px;
        border-top: 2px solid #ff1933;
        li {
          text-align: center;
          justify-content: center;
          a {
            padding-bottom: 0px !important;
          }
        }
      }
      &:hover .fw_dropdown3 {
        display: block;
      }
    }
  }
}
.fixed {
  position: fixed;
  top: 0;
  z-index: 99;
  // animation: slideDown 0.35s ease-out;
  opacity: 1;
  .fw_dropdown {
    top: 100% !important;
  }
}
// @keyframes slideDown {
//   from {
//     transform: translateY(-100%);
//   }
//   to {
//     transform: translateY(0);
//   }
// }
.nav_height {
  height: 131.5px;
}
@media (min-width: 220px) and (max-width: 1199px) {
  .main_nav {
    display: none;
  }
}

.fixe {
  min-height: 170px;
}
.w_100 {
  width: 100%;
}
@media (min-width: 220px) and (max-width: 1199px) {
  .nav_height {
    height: 107px !important;
  }
}
