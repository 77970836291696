$main-color: #ff1933;
$primary-color: #fd1b2f;
$primary-color-light: #fff;
$text-color-light: #727272;
$text-color: #4c4c4c;
$main-color: #ff1933;
$black: #000;
$grey: rgb(160, 160, 160);
$white: #fff;
$text-light: #666;
$light-grey: rgb(197, 197, 197);
