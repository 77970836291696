.landing_pop {
  background-color: transparent !important;
  max-width: 600px;

  .modal-content {
    background-color: transparent !important;
    border: 0 !important;
  }
  .btn-close {
    top: -2%;
    right: -2%;
  }
  .offer_modal_new {
    background-color: transparent !important;
  }
}

.offer_modal_new {
  position: relative;
  cursor: pointer;
  background-color: #ffe9ec;
  margin-bottom: 0 !important;
  span {
    width: 100% !important;
    height: 100% !important;
    position: relative;
    margin-bottom: 0 !important;
    img {
      width: 100% !important;
      margin-bottom: 0 !important;
    }
  }
  .offer_tandc {
    width: 8px;
    position: absolute;
    right: 4%;
    bottom: 23%;
    height: 50px;
    img {
      width: 100% !important;
    }
  }
}
.custom_border_0 {
  border: 0px !important;
}
.desktop_landing_pop_offer {
  width: 100%;
  margin-bottom: -6px;
}
.mobile_landing_pop_offer {
  width: 100%;
  display: none !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offer_mg_page .form-control {
    height: 30px !important;
  }
  .offer_mg_page .button1 {
    width: 150px;
    height: 30px;
    img {
      width: 100% !important;
      height: 30px !important;
    }
  }
  .offer_mg_page .text-header {
    padding-top: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offer_mg_page .form-control {
    height: 30px !important;
  }
  .offer_mg_page .button1 {
    width: 150px;
    height: 30px;
    img {
      width: 100% !important;
      height: 30px !important;
    }
  }
  .offer_mg_page .text-header {
    padding-top: 35px !important;
    span {
      width: 260px !important;
      height: 100px !important;
      margin: 0 auto !important;
    }
    img {
      margin: 0 auto !important;
      width: 260px !important;
      height: 100px !important;
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .try_container {
    display: none;
  }
  .landing_pop {
    max-width: 400px;
    .desktop_landing_pop_offer {
      display: none !important;
    }
    .mobile_landing_pop_offer {
      display: block !important;
      margin-bottom: -6px;
    }
  }
  .desktop-bg-off {
    display: none !important;
  }
  .mobile-bg-off {
    display: block !important;
  }

  .offer_mg_page .text-header {
    padding-top: 30px;
  }
  .offer_mg_page .custom-tandc .form-check-label {
    color: #fff;
    a {
      color: #fff !important;
    }
  }
  .offer_mg_page {
    .form_container {
      label {
        color: #fff;
      }
    }
  }
}
