.login_container {
  .login_image {
    width: 50%;
    float: left;
    height: 390px;
    overflow: hidden;
  }
}
.contact_form_wrapper {
  float: right;
  width: 50%;
  padding: 20px;
  p {
    font-size: 14px;
    padding-top: 15px;
    .new_here,
    a,
    span {
      color: #ff1933;
    }
  }
  form {
    padding: 20px;
    border: 1.8px dashed #ff1933;
    min-height: 350px;
    h1 {
      color: #ff1933;
      padding: 15px 0px;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }
    input {
      border-radius: 0;
      border: 1px solid #ff1933;
      background: #fff;
      width: 100%;
      padding: 7px 10px;
      margin: 14px 0px;
    }
    .submit_btn {
      background-color: #ff1933;
      padding: 5px 10px;
      color: #fff;
      font-size: 16px;
      margin: 7px 0px;
      width: 100%;
      border: 1px solid #ff1933;
    }
  }
}
.signup_form_wrapper {
  float: right;
  width: 50%;
  padding: 20px 20px;
  a {
    font-size: 15px;
  }
  p {
    font-size: 14px;
    padding-top: 5px;
    a,
    span {
      color: #ff1933;
    }
  }
  form {
    padding: 15px;
    border: 1.8px dashed #ff1933;
    min-height: 350px;
    h1 {
      color: #ff1933;
      padding: 0px;
      padding-bottom: 0px;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }
    input {
      border-radius: 0;
      border: 1px solid #ff1933;
      background: #fff;
      width: 100%;
      padding: 7px 10px;
      margin: 7px 0px;
    }
    .submit_btn {
      background-color: #ff1933;
      padding: 5px 10px;
      color: #fff;
      font-size: 16px;
      margin: 7px 0px;
      width: 100%;
      border: 1px solid #ff1933;
    }
  }
}
.sign_up_container .login_image {
  width: 50%;
  float: left;
  height: 449px;
  overflow: hidden;
}
.signup_form_wrapper {
  h1 {
    padding: 15px 0;
  }
}
.resendotp {
  width: 100%;
  border: 0;
  text-align: center;
  background-color: transparent;
  &:focus {
    border: 0;
    box-shadow: none;
  }
}
@media only screen and (min-width: 220px) and (max-width: 991px) {
  .contact_form_wrapper,
  .signup_form_wrapper {
    width: 100%;
  }
  .contact_form_wrapper p {
    font-size: 14px;
    padding-top: 10px;
    margin-bottom: 0px;
    text-align: center;
  }
  .login_image,
  .sign_up_container .login_image {
    display: none;
  }
}
