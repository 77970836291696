$main-color: #ff1932;
$white: #fff;
$grey: #808080;
$bg-light: #f4f4f6;
$text-color-light: #727272;
$text-color-dark: #000;
$primary-color: #fd1b2f;
$light-color-grey: #ccc;
$text-color: #4c4c4c;
$bg-primary: #f7f7f7;
$light-medium-grey: #f4f4f6;
$dark-grey: #9e9e9e;
$medium-grey: #e6e6e6;
$items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

.top_mobile_header {
  background-color: #fff;
  padding: 10px 0 10px 0;
  .dropdown {
    position: relative;
    display: inline-block;
    .dropbtn {
      background: transparent;
      border: none;
      outline: none;
      color: #252324;
      text-transform: uppercase;
    }
  }
  .buy_icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .icon_first {
    padding-left: 18px;
    position: relative;
    .drop_down_main {
      a {
        color: #111;
        justify-content: flex-start;
        display: flex;
      }
    }
    .drop_price_change {
      background-color: transparent;
      color: #111;
      display: flex;
      padding: 5px 10px;
      border: 0;
      width: 86px;
      img {
        width: 30px;
        height: auto;
      }
      &::after {
        margin-top: 10px;
      }
    }
    :global(.dropdown-menu) {
      border: 1px solid #f5f5f5;
      box-shadow: 0 16px 23px 0 hsla(0, 0%, 43%, 0.4);
    }
    a {
      position: relative;

      img {
        max-width: 30px !important;
        max-height: 30px !important;
        width: 100% !important;
      }
      .top_card {
        position: absolute;
        right: 6px;
        top: -6px;
        background-color: #fff;
        color: #ff1933;
        font-size: 14px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-weight: 600;
        line-height: 19px;
      }
      .top_card_card {
        position: absolute;
        right: -8px;
        top: -18px;
        background-color: #fff;
        color: #ff1933;
        font-size: 14px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-weight: 600;
        line-height: 19px;
        text-align: center;
      }
    }
    .maincart_mobile {
      position: absolute;
      top: -8px;
      right: -9px;
    }
  }
}
.down_header {
  position: relative;
  .header_container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 4px;
    .nav_icon {
      max-width: 30px;
      border: 0px;
      button {
        border: 0px;
        background-color: transparent;
        padding-top: 4px;
        span {
          display: flex;
          margin-bottom: 8px;
          position: relative;
          background: #808080;
          border-radius: 3px;
          transform-origin: 5px 0px;
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
          &:nth-child(1) {
            transform-origin: 0% 0%;
            width: 34px;
            height: 2px;
          }
          &:nth-child(2) {
            width: 26px;
            height: 2px;
          }
          &:nth-child(3) {
            transform-origin: 0% 0%;
            width: 34px;
            height: 2px;
          }
        }
      }
    }
    .middle_logo {
      max-width: 130px;
    }
    .search_mobile {
      max-width: 50px;
      img {
        width: 25px !important;
      }
    }
  }
}
.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 99;
  height: 62px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  animation: slideDown 0.35s ease-out;
  opacity: 1;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.slide_nav {
  max-width: 320px;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  left: 0px;
  z-index: 999;
  top: 0;
  bottom: 0;
  background: #fff;
  .user_profile {
    background: #ece8e0;
    padding: 20px 0;
    font-size: 15px;
    color: #fd1b2f;
    text-align: center;
    a {
      font-size: 16px;
      text-align: center;
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #000000;
    }
    .User_images {
      width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 10px;
      margin: 0 auto;
      img {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
  nav {
    ul {
      margin: 10px 0px;
      padding: 0px;
      li {
        list-style: none;
        font-size: 16px;
        padding: 5px 0px;
        padding-left: 35px;
        display: block;
        color: #000000;
        a {
          color: #000;
          font-size: 15px;
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          h1 {
            font-size: 15px;
            font-family: "Open Sans", sans-serif;
            font-weight: 500;
            margin: 0px;
            line-height: 24px;
          }
        }
      }
    }
    ul.nav_2 {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        font-size: 16px;
        padding: 12px 0px;
        padding-left: 15px;
        display: block;
        color: #000000;
        border-bottom: 1px solid rgba(255, 25, 50, 0.39);
        a {
          color: #000;
          font-size: 16px;
          h1 {
            font-size: 15px;
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
            margin: 0px;
          }
        }
      }
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  overflow: hidden;
}
.mobile_header_view {
  display: none;
}
.search_box {
  position: relative;

  width: 100%;
  background: #fff;
  padding: 1px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  box-shadow: 0 18px 20px -12px rgb(0 0 0 / 20%);

  input {
    width: 100%;
    border: 1px solid #ff1932;
    border-right: 0px;
    background-color: #fff;
    height: 35px;
    padding: 6px;
    border-radius: 0px;
    &:focus {
      outline: 0px;
    }
  }
  button {
    width: 50px;
    border: 1px solid #ff1932;
    height: 35px;
    padding: 5px 0;
    background-color: #fff;
    border-radius: 0px;
    a {
      font-weight: 400;
      font-size: 15px;
      font-family: "Open Sans", sans-serif;
    }
  }
}
.map_w {
  width: 20px;
  display: table-cell;
  text-align: center;
  span {
    width: 100% !important;
  }
  img {
    width: 20px !important;
  }
}
@media (min-width: 220px) and (max-width: 1199px) {
  .mobile_header_view {
    display: block;
    height: auto;
  }
}
.suggestions {
  display: block;
  position: absolute;
  // border: 0.0625rem solid #d5d5d5;
  border-radius: 0.3125rem;
  background-color: #fff;
  top: auto;
  right: 0;
  width: 21.875rem;
  z-index: 9999;
  min-height: 20.3125rem;
  width: 100%;
  cursor: default;
  // box-shadow: inset 0 0.188rem 0.375rem rgba(0, 0, 0, 0.160784);
  padding: 15px;
  max-height: 71vh;
  overflow-y: auto;
  .suggestions_section_header {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 3px 0;
        img {
          width: 20px !important;
          height: auto !important;
        }
        .span {
          width: 10px !important;
          height: auto;
        }
      }
    }
  }
  .border_contant {
    padding: 5px 0;
    li {
      display: inline-block;
      margin: 5px;
      margin-left: 0;
      margin-right: 10px;
      a {
        border: 1px solid #ddd;
        padding: 3px 10px;
        display: inline-block;
        border-radius: 20px;
      }
    }
  }
  .popular_products {
    width: 90%;
    position: relative;
    padding: 5px 0px;
    margin: 0 auto;
    .popular_products_card {
      display: block;
      width: 100%;
      margin: 0;
      min-height: 198px;
      position: relative;
      padding-bottom: 0;
      padding: 5px;
      background-color: #fff;
      transition: box-shadow 0.3s;
      border: 1px solid #ddd;
      height: 96%;
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }
}
nav {
  .accordion_item {
    border: 1px solid rgb(255 25 50 / 39%);
    border-left: 0;
    border-radius: 0 !important;
    border-right: 0;
  }

  .accordion .accordion_item button[aria-expanded="false"]::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("../assets/images/plus.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
    box-shadow: none;
  }
  .accordion .accordion_item button[aria-expanded="true"]::after {
    background-image: url("../assets/images/minus.svg");
    transform: rotate(-180deg);
    box-shadow: none;
  }
  .accordion .accordion_item button[aria-expanded="true"] {
    color: #ff1d25 !important;
    background-color: #fff8f8;
    box-shadow: none;
    border-radius: 0;
  }
  .accordion
    .accordion_item
    .accordion_body
    .accordion
    .accordion_item_secle
    button[aria-expanded="true"] {
    color: #ff1d25 !important;
    background-color: #fff8f8;
    box-shadow: inset 0 -1px 0 #ccc !important;
  }
  .accordion {
    a {
      font-size: 15px;
      font-family: Open Sans, sans-serif;
      font-weight: 600;
    }
    button {
      color: #222 !important;
    }
    .accordion_item {
      .accordion_body {
        padding: 0px !important;
        button {
          background-color: #f5f5f5 !important;
          padding-left: 30px;
          border-top: 1px solid #ccc !important;
          border-bottom: 0 solid #ccc !important;
          border-radius: 0 !important;
          color: #222;
          &:not(.collapsed),
          &:focus {
            color: #ff1d25;
            background-color: #fff8f8;
            box-shadow: none;
            border-radius: 0;
          }
        }
      }
      .accordion {
        button {
          background-color: #f5f5f5 !important;
          padding-left: 30px;
          border-top: 1px solid #ccc !important;
          border-bottom: 0 solid #ccc !important;
          border-radius: 0 !important;
          color: #222;
          a {
            font-size: 15px;
            font-family: Open Sans, sans-serif;
            font-weight: 600;
          }
        }
        a {
          font-size: 15px;
          font-family: Open Sans, sans-serif;
          font-weight: 500;
        }
      }
    }
    .accordion-button {
      padding-left: 15px;
    }
    .accordion {
      .accordion-header {
        border-radius: 0px !important;
        button {
          background-color: #f5f5f5 !important;
          padding-left: 30px;
          border-top: 1px solid #ccc !important;
          border-bottom: 0px solid #ccc !important;
          border-radius: 0px !important;
          &::after {
            flex-shrink: 0;
            width: 1.25rem;
            height: 1.25rem;
            margin-left: auto;
            content: "";
            background-image: url("../assets/images/plus_black.svg") !important;
            background-repeat: no-repeat;
            background-size: 1.25rem;
            transition: transform 0.2s ease-in-out;
          }
        }
      }
      .accordion-item {
        border: 0px solid rgba(255, 25, 50, 0.39) !important;
        border-left: 0;
        border-radius: 0 !important;

        border-right: 0;
      }
    }
  }
}

.popular_products_search {
  .swiper-button-next {
    right: -5px;
    &::after {
      font-size: 20px;
    }
  }
  .swiper-button-prev {
    left: -5px;
    &::after {
      font-size: 20px;
    }
  }
}

.popular_products_search .arrow_left {
  position: absolute;
  left: -12px;
  width: 30px;

  content: "";
  top: 40%;
  background: transparent;
  border: 0;
  font-size: 30px;
}
.popular_products_search {
  position: relative;
}
.popular_products_search .arrow_right {
  position: absolute;
  right: -12px;
  width: 30px;

  content: "";
  top: 40%;
  background: transparent;
  border: 0;
  font-size: 30px;
}
.crosss {
  width: 10px !important;
  height: 10px !important;
}

.mobile_nav_inerbg_fixe {
  display: none;
  height: 0px;
}
@media (min-width: 220px) and (max-width: 1199px) {
  .active_fixe {
    display: block;
    min-height: 26px;
  }
  .nav_height {
    height: 107.5px;
  }
}
