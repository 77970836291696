.new_add_form {
  width: 100%;
  position: relative;
  border: 1px dashed #ff1933;
  padding: 25px;
  font-family: "Philosopher", sans-serif;
  label {
    font-size: 16px;
    sup {
      color: #ff1933;
    }
  }
  .input_text {
    width: 100%;
    height: 40px;
    background-color: #fff;
    border: 0;
    border: 1px solid #ddd;
    outline: 0;
    padding: 7px 10px;
    margin-bottom: 0px;
    border-radius: 5px;
  }
  .input_textarea {
    border-radius: 5px;
    width: 100%;
    background-color: #fff;
    border: 0;
    border: 1px solid #ddd;
    outline: 0;
    padding: 7px 10px;
    margin-bottom: 0px;
  }
  .save {
    background-color: #ff1933;
    border: 0;
    outline: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    padding: 8px 25px;
    margin-top: 30px;
    font-weight: 600;
    width: 200px;
    display: block;
    margin: 0 auto;
    border-radius: 5px;
  }
}
.new_address {
  padding: 25px;
  background-image: url("../../../assets/images/bg-gift.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .bill_head {
    width: 100%;
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    color: #ff1933;
    margin-bottom: 15px;
  }
  select {
    background-color: #f6f6f6;
    width: 100%;
    display: block;
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
  }
}
.new_address_new {
  .new_add_form {
    width: 100%;
    position: relative;
    border: 0px solid #ddd;
    padding: 0px;
    font-family: "Philosopher", sans-serif;
    label {
      font-size: 16px;
      sup {
        color: #ff1933;
      }
    }
    .input_text {
      width: 100%;
      height: 40px;
      background-color: #fff;
      border: 0;
      border: 1px solid #ddd;
      outline: 0;
      padding: 7px 10px;
      margin-bottom: 0px;
      border-radius: 5px;
    }
    .input_textarea {
      border-radius: 5px;
      width: 100%;
      background-color: #fff;
      border: 0;
      border: 1px solid #ddd;
      outline: 0;
      padding: 7px 10px;
      margin-bottom: 0px;
    }
    .save {
      background-color: #ff1933;
      border: 0;
      outline: 0;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      padding: 8px 25px;
      margin-top: 30px;
      font-weight: 600;
      width: 200px;
      display: block;
      margin: 0 auto;
      border-radius: 5px;
    }
  }
}
.new_address.new_address_new {
  padding: 0px;
  background: transparent;
  background-repeat: no-repeat;
  border: 0px solid #ddd;
  background-size: 100% 100%;
  h4 {
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #efefef;
    font-size: 18px;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
  }
  .bill_head {
    width: 100%;
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    color: #ff1933;
    margin-bottom: 15px;
  }
  select {
    background-color: #f6f6f6;
    width: 100%;
    display: block;
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
  }
}
.new_address {
  .nav {
    justify-content: center;
    border: 0px;
    li {
      &:last-child {
        button {
          border-left: 1px solid #fff;
        }
      }
      button {
        border-radius: 0px;
        border: 1px solid #ddd;
        color: #666;
        margin: 0px;
        &.active {
          border-bottom: 1px solid #ddd;
          color: #ff1933;
        }
      }
    }
  }
}
